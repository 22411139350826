// components/SuperAdminLayout.jsx
import React, { useState } from 'react';
import SuperAdminHeader from './SuperAdminHeader';
import SuperAdminSidebar from './SuperAdminSidebar';
import '../styles/SuperAdminLayout.css';

export default function SuperAdminLayout({ children }) {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => setSidebarOpen((prev) => !prev);

  return (
    <div className="app-layout">
      <SuperAdminSidebar isOpen={sidebarOpen} />
      <div className="main-content">
        <SuperAdminHeader toggleSidebar={toggleSidebar} />
        <div className="content-area">{children}</div>
      </div>
    </div>
  );
}
