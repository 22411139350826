// src/components/SchoolAdminLayout.jsx
import React, { useState } from 'react';
import SchoolAdminHeader from './SchoolAdminHeader';
import SchoolAdminSidebar from './SchoolAdminSidebar';
import "../styles/SchoolAdminLayout.css";

const SchoolAdminLayout = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="schooladmin-layout">
      <SchoolAdminSidebar isOpen={sidebarOpen} />
      <div className="main-content">
        <SchoolAdminHeader toggleSidebar={toggleSidebar} />
        <div className="content-area">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SchoolAdminLayout;
