// src/pages/SchoolAdmin/Students/StudentsList.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../api';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';
import '../../../styles/Students/StudentsList.css';

const StudentsList = () => {
  // Existing state for listing and pagination
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10;

  // New state variables for roll number modal
  const [rollModalOpen, setRollModalOpen] = useState(false);
  const [selectedStudentForRoll, setSelectedStudentForRoll] = useState(null);
  const [rollNumber, setRollNumber] = useState('');
  const [rollModalLoading, setRollModalLoading] = useState(false);
  const [rollModalError, setRollModalError] = useState(null);

  // Fetch all students on page load
  const fetchStudents = async () => {
    try {
      setLoading(true);
      const response = await api.get('/student');
      setStudents(response.data.students);
    } catch (err) {
      setError('Error loading students');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  // Filter and paginate the students list
  const filteredStudents = students.filter((student) =>
    `${student.first_name} ${student.last_name}`.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);
  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const handleDeleteStudent = async (studentId) => {
    if (window.confirm('Are you sure you want to delete this student?')) {
      try {
        await api.patch(`/student/${studentId}/status`, { status: 'terminated' });
        // Re-fetch students after deletion
        fetchStudents();
      } catch (err) {
        console.error('Failed to delete student', err);
      }
    }
  };

  // ----- ROLL NUMBER MODAL FUNCTIONS -----
  // Open the modal and fetch the current roll number (if any) for the selected student.
  const openRollNumberModal = async (student) => {
    setSelectedStudentForRoll(student);
    setRollModalError(null);
    setRollModalLoading(true);
    try {
      // Using a query parameter to pass the student_id
      const response = await api.get(`/student/roll-number?student_id=${student.student_id}`);
      setRollNumber(response.data.roll_number || '');
    } catch (err) {
      setRollModalError('Error fetching roll number');
      console.error(err);
    } finally {
      setRollModalLoading(false);
      setRollModalOpen(true);
    }
  };

  // Close the roll number modal and reset its state
  const closeRollNumberModal = () => {
    setRollModalOpen(false);
    setSelectedStudentForRoll(null);
    setRollNumber('');
    setRollModalError(null);
  };

  // Handle updating (or assigning) the roll number via API
  const handleRollNumberUpdate = async () => {
    if (!selectedStudentForRoll) return;
    try {
      setRollModalLoading(true);
      // Call the PUT endpoint with the student_id and new roll_number
      await api.put('/roll-number/update', {
        student_id: selectedStudentForRoll.student_id,
        roll_number: rollNumber,
      });
      // Optionally, re-fetch the students list to reflect the update
      // fetchStudents();
      closeRollNumberModal();
    } catch (err) {
      setRollModalError('Error updating roll number');
      console.error(err);
    } finally {
      setRollModalLoading(false);
    }
  };
  // ----- END ROLL NUMBER MODAL FUNCTIONS -----

  if (loading) return <div>Loading students...</div>;
  if (error) return <div>{error}</div>;

  return (
    <SchoolAdminLayout>
      <div className="students-container">
        <div className="students-header">
          <h1>Student Management</h1>
          <div className="controls">
            <input
              type="text"
              placeholder="Search by name..."
              value={searchQuery}
              onChange={handleSearch}
            />
            <Link to="/school-admin/students/create">
              <button className="create-btn">＋ Create New Student</button>
            </Link>
          </div>
        </div>
        <table className="students-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Student ID</th>
              <th>Gender</th>
              <th>Contact</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {currentStudents.map((student) => (
              <tr key={student.student_id}>
                <td>
                  {student.first_name} {student.last_name}
                </td>
                <td>{student.student_id}</td>
                <td>{student.gender}</td>
                <td>{student.primary_contact}</td>
                <td>
                  <Link to={`/school-admin/students/${student.student_id}`}>
                    <button className="action-btn view">👁️ View</button>
                  </Link>
                  <Link to={`/school-admin/students/${student.student_id}/edit`}>
                    <button className="action-btn edit">✏️ Edit</button>
                  </Link>
                  <button
                    className="action-btn roll"
                    onClick={() => openRollNumberModal(student)}
                  >
                    🎫 Roll Number
                  </button>
                  <button
                    className="action-btn delete"
                    onClick={() => handleDeleteStudent(student.student_id)}
                  >
                    🗑️ Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => setCurrentPage(i + 1)}
              className={currentPage === i + 1 ? 'active' : ''}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>

      {/* Modal for assigning/updating roll number */}
      {rollModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <h2>Assign/Update Roll Number</h2>
            {rollModalLoading ? (
              <p>Loading...</p>
            ) : (
              <>
                <p>
                  <strong>Student:</strong> {selectedStudentForRoll.first_name}{' '}
                  {selectedStudentForRoll.last_name} (ID: {selectedStudentForRoll.student_id})
                </p>
                {rollModalError && <p className="error">{rollModalError}</p>}
                <input
                  type="text"
                  placeholder="Enter roll number"
                  value={rollNumber}
                  onChange={(e) => setRollNumber(e.target.value)}
                />
                <div className="modal-buttons">
                  <button onClick={handleRollNumberUpdate}>Update</button>
                  <button onClick={closeRollNumberModal} className="cancel-button">
                    Cancel
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </SchoolAdminLayout>
  );
};

export default StudentsList;
