import React, { useState, useEffect, useMemo } from 'react';
import api from '../../api';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/ResultPage.css';

// A simple skeleton loader component
const SkeletonLoader = ({ width, height, style }) => (
  <div
    className="skeleton-loader"
    style={{ width, height, ...style }}
  ></div>
);

const FillResultPage = () => {
  // Data states
  const [activeSessions, setActiveSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [students, setStudents] = useState([]);
  const [selectedEnrollment, setSelectedEnrollment] = useState(null);
  const [examTerms, setExamTerms] = useState([]);
  const [selectedExamTerm, setSelectedExamTerm] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [subjectResults, setSubjectResults] = useState({});

  // Loading states
  const [isLoadingClasses, setIsLoadingClasses] = useState(false);
  const [isLoadingStudents, setIsLoadingStudents] = useState(false);
  const [isLoadingExamTerms, setIsLoadingExamTerms] = useState(false);
  const [isLoadingSubjects, setIsLoadingSubjects] = useState(false);

  // View state (list vs. entry)
  const [view, setView] = useState("list");

  // Load active sessions from localStorage
  useEffect(() => {
    const sessions = JSON.parse(localStorage.getItem('activeSessions') || '[]');
    setActiveSessions(sessions);
    if (sessions.length > 0) {
      setSelectedSession(sessions[0].id);
    }
  }, []);

  // Fetch classes for selected session
  useEffect(() => {
    if (selectedSession) {
      setIsLoadingClasses(true);
      api.get(`/classes/all?session_id=${selectedSession}`)
        .then((res) => {
          setClasses(res.data.classes);
          if (res.data.classes.length > 0) {
            setSelectedClass(res.data.classes[0].id);
          } else {
            setSelectedClass('');
          }
        })
        .catch((err) => console.error("Error fetching classes:", err))
        .finally(() => setIsLoadingClasses(false));
    }
  }, [selectedSession]);

  // Fetch students for selected class (filtering by active status)
  useEffect(() => {
    if (selectedClass) {
      setIsLoadingStudents(true);
      api.post('/student/enrollment-details', { session_id: selectedSession })
        .then((res) => {
          const filtered = res.data.students.filter(student =>
            student.class_id === parseInt(selectedClass, 10) && student.status === "active"
          );
          setStudents(filtered);
        })
        .catch((err) => console.error("Error fetching students:", err))
        .finally(() => setIsLoadingStudents(false));
    }
  }, [selectedClass, selectedSession]);

  // Handle student selection for result entry
  const handleGiveMarks = (enrollment) => {
    setSelectedEnrollment(enrollment);
    setView("entry");
    setSelectedExamTerm('');
    setSubjects([]);
    setSubjectResults({});
    setIsLoadingExamTerms(true);
    api.get(`/grading/exam-terms?session_id=${selectedSession}&class_id=${selectedClass}`)
      .then((res) => {
        setExamTerms(res.data.exam_terms);
        if (res.data.exam_terms.length > 0) {
          setSelectedExamTerm(res.data.exam_terms[0].id);
        }
      })
      .catch((err) => console.error("Error fetching exam terms:", err))
      .finally(() => setIsLoadingExamTerms(false));
  };

  // Derive full details of the selected exam term
  const selectedExamTermDetails = useMemo(() => {
    return examTerms.find(term => term.id.toString() === selectedExamTerm.toString());
  }, [examTerms, selectedExamTerm]);

  // Fetch subjects and any existing results when enrollment and exam term are selected
  useEffect(() => {
    if (selectedEnrollment && selectedExamTerm) {
      setIsLoadingSubjects(true);
      api.get(`/results/subjects?enrollment_id=${selectedEnrollment.enrollment_id}`)
        .then((res) => {
          setSubjects(res.data.subjects);
          // Now get existing results for this exam term (if any)
          api.get(`/results/?enrollment_id=${selectedEnrollment.enrollment_id}&exam_term_id=${selectedExamTerm}`)
            .then((resultRes) => {
              const existing = {};
              resultRes.data.results.forEach(r => {
                existing[r.subject_id] = {
                  theory_marks: r.theory_marks,
                  practical_marks: r.practical_marks,
                  is_absent: r.is_absent
                };
              });
              setSubjectResults(existing);
            })
            .catch((err) => console.error("Error fetching existing results:", err));
        })
        .catch((err) => console.error("Error fetching subjects:", err))
        .finally(() => setIsLoadingSubjects(false));
    }
  }, [selectedEnrollment, selectedExamTerm]);

  // Handle changes for each subject's marks or absence
  const handleSubjectChange = (subject_id, field, value) => {
    setSubjectResults(prev => ({
      ...prev,
      [subject_id]: {
        ...(prev[subject_id] || { theory_marks: '', practical_marks: '', is_absent: false }),
        [field]: value
      }
    }));
  };

  // Submit results payload based on marks_scheme of the exam term
  const handleSubmitResults = async () => {
    const resultsArray = subjects.map(subj => {
      const resultData = subjectResults[subj.subject_id] || { theory_marks: '', practical_marks: '', is_absent: false };
      if (selectedExamTermDetails?.marks_scheme === 'dual') {
        return {
          subject_id: subj.subject_id,
          theory_marks: resultData.theory_marks,
          practical_marks: resultData.practical_marks,
          is_absent: resultData.is_absent
        };
      } else { // single scheme
        return {
          subject_id: subj.subject_id,
          theory_marks: resultData.theory_marks,
          is_absent: resultData.is_absent
        };
      }
    });
    try {
      await api.post('/results', {
        enrollment_id: selectedEnrollment.enrollment_id,
        exam_term_id: selectedExamTerm,
        subject_results: resultsArray
      });
      alert("Results submitted successfully");
      setView("list");
    } catch (err) {
      console.error(err);
      alert(err.response?.data?.message || "Error submitting results");
    }
  };

  return (
    <SchoolAdminLayout>
      <div className="page-container">
        {view === "list" ? (
          <>
            <h1 className="page-title">Fill Student Results</h1>
            <div className="session-selector">
              <label>Select Session</label>
              <select value={selectedSession} onChange={(e) => setSelectedSession(e.target.value)}>
                {activeSessions.map((session) => (
                  <option key={session.id} value={session.id}>{session.name}</option>
                ))}
              </select>
            </div>
            <div className="class-selector">
              <label>Select Class</label>
              {isLoadingClasses ? (
                <SkeletonLoader width="100%" height="40px" />
              ) : (
                <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
                  {classes.map(cls => (
                    <option key={cls.id} value={cls.id}>{cls.name}</option>
                  ))}
                </select>
              )}
            </div>
            <div className="student-list">
              <h2>Students</h2>
              {isLoadingStudents ? (
                <ul>
                  {[1, 2, 3].map((n) => (
                    <li key={n} className="student-item">
                      <div className="student-info">
                        <SkeletonLoader width="150px" height="20px" style={{ marginBottom: '5px' }} />
                        <SkeletonLoader width="100px" height="15px" />
                      </div>
                      <SkeletonLoader width="80px" height="30px" />
                    </li>
                  ))}
                </ul>
              ) : (
                students.length === 0 ? (
                  <p>No active students found for this class.</p>
                ) : (
                  <ul>
                    {students.map(student => (
                      <li key={student.enrollment_id} className="student-item">
                        <div className="student-info">
                          <span className="student-name">{student.first_name} {student.last_name}</span>
                          <span className="student-roll">Roll: {student.roll_number}</span>
                        </div>
                        <button className="give-marks-btn" onClick={() => handleGiveMarks(student)}>
                          Give Marks
                        </button>
                      </li>
                    ))}
                  </ul>
                )
              )}
            </div>
          </>
        ) : (
          <>
            <h1 className="page-title">Enter Results for Student</h1>
            <div className="result-info">
              <p>
                <strong>Student:</strong> {selectedEnrollment.first_name} {selectedEnrollment.last_name} (Roll: {selectedEnrollment.roll_number})
              </p>
            </div>
            <div className="exam-term-selector">
              <label>Select Exam Term</label>
              {isLoadingExamTerms ? (
                <SkeletonLoader width="100%" height="40px" />
              ) : (
                <select value={selectedExamTerm} onChange={(e) => setSelectedExamTerm(e.target.value)}>
                  {examTerms.map(term => (
                    <option key={term.id} value={term.id}>
                      {term.term_name} - {term.marks_scheme === 'single' ? term.theory_marks : `${term.theory_marks} / ${term.practical_marks}`}
                    </option>
                  ))}
                </select>
              )}
            </div>
            <div className="subjects-entry">
              <h2>Subjects & Marks</h2>
              {isLoadingSubjects ? (
                <table className="subjects-table">
                  <thead>
                    <tr>
                      <th>Subject</th>
                      {selectedExamTermDetails?.marks_scheme === 'dual' ? (
                        <>
                          <th>{selectedExamTermDetails.component_1_label || "Theory Marks"}</th>
                          <th>{selectedExamTermDetails.component_2_label || "Practical Marks"}</th>
                        </>
                      ) : (
                        <th>{selectedExamTermDetails?.component_1_label || "Theory Marks"}</th>
                      )}
                      <th>Absent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[1, 2, 3, 4].map((n) => (
                      <tr key={n}>
                        <td><SkeletonLoader width="100px" height="20px" /></td>
                        {selectedExamTermDetails?.marks_scheme === 'dual' ? (
                          <>
                            <td><SkeletonLoader width="80px" height="20px" /></td>
                            <td><SkeletonLoader width="80px" height="20px" /></td>
                          </>
                        ) : (
                          <td><SkeletonLoader width="80px" height="20px" /></td>
                        )}
                        <td><SkeletonLoader width="20px" height="20px" /></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                subjects.length === 0 ? (
                  <p>No result subjects available for this enrollment.</p>
                ) : (
                  <table className="subjects-table">
                    <thead>
                      <tr>
                        <th>Subject</th>
                        {selectedExamTermDetails?.marks_scheme === 'dual' ? (
                          <>
                            <th>{selectedExamTermDetails.component_1_label || "Theory Marks"}</th>
                            <th>{selectedExamTermDetails.component_2_label || "Practical Marks"}</th>
                          </>
                        ) : (
                          <th>{selectedExamTermDetails?.component_1_label || "Theory Marks"}</th>
                        )}
                        <th>Absent</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subjects.map((subj) => (
                        <tr key={subj.subject_id}>
                          <td>{subj.name} ({subj.code})</td>
                          {selectedExamTermDetails?.marks_scheme === 'dual' ? (
                            <>
                              <td>
                                <input
                                  type="number"
                                  placeholder={selectedExamTermDetails.component_1_label || "Theory Marks"}
                                  value={
                                    subjectResults[subj.subject_id] && subjectResults[subj.subject_id].theory_marks !== undefined
                                      ? subjectResults[subj.subject_id].theory_marks
                                      : ''
                                  }
                                  onChange={(e) => handleSubjectChange(subj.subject_id, 'theory_marks', e.target.value)}
                                />
                              </td>
                              <td>
                                <input
                                  type="number"
                                  placeholder={selectedExamTermDetails.component_2_label || "Practical Marks"}
                                  value={
                                    subjectResults[subj.subject_id] && subjectResults[subj.subject_id].practical_marks !== undefined
                                      ? subjectResults[subj.subject_id].practical_marks
                                      : ''
                                  }
                                  onChange={(e) => handleSubjectChange(subj.subject_id, 'practical_marks', e.target.value)}
                                />
                              </td>
                            </>
                          ) : (
                            <td>
                              <input
                                type="number"
                                placeholder={selectedExamTermDetails?.component_1_label || "Theory Marks"}
                                value={
                                  subjectResults[subj.subject_id] && subjectResults[subj.subject_id].theory_marks !== undefined
                                    ? subjectResults[subj.subject_id].theory_marks
                                    : ''
                                }
                                onChange={(e) => handleSubjectChange(subj.subject_id, 'theory_marks', e.target.value)}
                              />
                            </td>
                          )}
                          <td>
                            <input
                              type="checkbox"
                              checked={subjectResults[subj.subject_id]?.is_absent || false}
                              onChange={(e) => handleSubjectChange(subj.subject_id, 'is_absent', e.target.checked)}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )
              )}
            </div>
            <div className="result-actions">
              <button className="submit-btn" onClick={handleSubmitResults}>Submit Results</button>
              <button className="back-btn" onClick={() => setView('list')}>Back to Student List</button>
            </div>
          </>
        )}
      </div>
    </SchoolAdminLayout>
  );
};

export default FillResultPage;
