import React from 'react';
import { useNavigate } from 'react-router-dom';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/SchoolAdminSetting.css';

const settingsOptions = [
  { label: "Assign Subject to School", path: "/school-admin/settings/assign-subject-school" },
  { label: "Assign Subject to Class", path: "/school-admin/settings/class-subject" },
  { label: "Configure Grading Pattern And Exam Terms", path: "/school-admin/settings/configure-grading" },
  
  { label: "Create a class", path: "/school-admin/settings/classes-page" },
  { label: "Assign Section to class", path: "/school-admin/settings/assign-section" }
];

const SchoolAdminSettings = () => {
  const navigate = useNavigate();

  return (
    <SchoolAdminLayout>
      <div className="settings-container">
        <h2>School Settings</h2>
        <div className="settings-grid">
          {settingsOptions.map((option, index) => (
            <button key={index} className="settings-btn" onClick={() => navigate(option.path)}>
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </SchoolAdminLayout>
  );
};

export default SchoolAdminSettings;
