// src/components/SchoolAdminHeader.jsx
import React from 'react';
import { FiMenu, FiUser, FiSettings, FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import '../styles/SchoolAdminHeader.css';

const SchoolAdminHeader = ({ toggleSidebar }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear tokens and user details on logout
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    localStorage.removeItem('role');
    navigate('/login');
  };

  return (
    <header className="schooladmin-header">
      <div className="header-left">
        <button className="menu-toggle" onClick={toggleSidebar}>
          <FiMenu size={24} />
        </button>
        <h1>School Admin</h1>
      </div>
      <div className="header-right">
        <button className="header-btn profile-btn">
          <FiUser size={24} />
        </button>
        <button className="header-btn settings-btn" onClick={() => navigate('/school-admin/setting')}>
          <FiSettings size={24} />
        </button>

        <button className="header-btn logout-btn" onClick={handleLogout}>
          <FiLogOut size={24} />
        </button>
      </div>
    </header>
  );
};

export default SchoolAdminHeader;
