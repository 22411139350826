// src/App.js
import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

// Import React Query Provider components
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Super Admin Pages
import SuperAdminLogin from "./pages/SuperAdmin/Login";
import SuperAdminDashboard from "./pages/SuperAdmin/Dashboard";
import ManageSchools from "./pages/SuperAdmin/Schools";
import CreateSchool from "./pages/SuperAdmin/CreateSchool";

// School Admin Pages
import SchoolAdminLogin from "./pages/SchoolAdmin/Login";
import SchoolAdminDashboard from "./pages/SchoolAdmin/Dashboard";
import SchoolAdminSettings from "./pages/SchoolAdmin/SchoolAdminSetting";
import ClassesPage from "./pages/SchoolAdmin/ClassesPage";
import AssignSubjectSchool from "./pages/SchoolAdmin/AssignSubjectSchool";
import ClassSubjectsPage from "./pages/SchoolAdmin/ClassSubjectPage";
import ExamAndGrading from "./pages/SchoolAdmin/ExamAndGrading";
import FillResultPage from "./pages/SchoolAdmin/FillResultPage";
// Student Management Pages
import StudentsList from "./pages/SchoolAdmin/students/StudentsList";
import CreateStudent from "./pages/SchoolAdmin/students/CreateStudent";
import EditStudent from "./pages/SchoolAdmin/students/EditStudent";
import StudentDetail from "./pages/SchoolAdmin/students/StudentDetail";
import EnrollmentDetails from "./pages/SchoolAdmin/students/EnrollmentDetails";
import AssignSection from "./pages/SchoolAdmin/AssignSection";
import AdditionalSubjectsAssignment from "./pages/SchoolAdmin/students/AdditionalSubjectAssignment";
// (Optional) Global CSS import
import "./index.css";

// Create a React Query client with default options (e.g., 5-minute cache)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 300000, // 5 minutes in milliseconds
    },
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Routes>
          {/* Super Admin Routes */}
          <Route path="/super-admin" element={<Navigate to="/super-admin/login" />} />
          <Route path="/super-admin/login" element={<SuperAdminLogin />} />
          <Route path="/super-admin/dashboard" element={<SuperAdminDashboard />} />
          <Route path="/super-admin/schools" element={<ManageSchools />} />
          <Route path="/super-admin/schools/create" element={<CreateSchool />} />

          {/* School Admin Routes */}
          <Route path="/school-admin" element={<Navigate to="/school-admin/login" />} />
          <Route path="/school-admin/login" element={<SchoolAdminLogin />} />
          <Route path="/school-admin/dashboard" element={<SchoolAdminDashboard />} />
          <Route path="/school-admin/setting" element={<SchoolAdminSettings />} />
          <Route path="/school-admin/result" element={<FillResultPage />} />
          <Route path="/school-admin/settings/classes-page" element={<ClassesPage />} />
          <Route path="/school-admin/settings/assign-subject-school" element={<AssignSubjectSchool />} />
          <Route path="/school-admin/settings/class-subject" element={<ClassSubjectsPage />} />
          <Route path="/school-admin/settings/configure-grading" element={<ExamAndGrading />} />
          <Route path="/school-admin/settings/assign-section" element={<AssignSection />} />
          {/* Student Management Routes */}
          <Route path="/school-admin/student-managment/students" element={<StudentsList />} />
          <Route path="/school-admin/students/create" element={<CreateStudent />} />
          <Route path="/school-admin/students/:studentId/edit" element={<EditStudent />} />
          <Route path="/school-admin/students/:studentId" element={<StudentDetail />} />
          <Route path="/school-admin/enrollment-details" element={<EnrollmentDetails />} />
          <Route path="/school-admin/additional-subject-assignment" element={<AdditionalSubjectsAssignment />} />

          {/* Redirect unknown routes */}
          <Route path="*" element={<Navigate to="/school-admin/login" />} />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
