// src/pages/SchoolAdmin/SchoolAdminDashboard.jsx
import React, { useEffect, useState } from 'react';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/SchoolAdminDashboard.css';

export default function SchoolAdminDashboard() {
  const [schoolCode, setSchoolCode] = useState('');

  useEffect(() => {
    const userStr = sessionStorage.getItem('user');
    if (userStr) {
      const user = JSON.parse(userStr);
      setSchoolCode(user.school_code);
    }
  }, []);

  return (
    <SchoolAdminLayout>
      <div className="dashboard-content">
        <h1>Welcome {schoolCode}</h1>
        <p>You have successfully logged in as a School Admin.</p>
      </div>
    </SchoolAdminLayout>
  );
}
