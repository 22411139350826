import React, { useState, useEffect } from "react";
import api from "../../api"; // Your Axios instance
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "../../styles/AssignSection.css"; // Import your CSS styles
import SchoolAdminLayout from "../../components/SchoolAdminLayout";

const AssignSection = () => {
  // State variables
  const [activeSessions, setActiveSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState("");
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [sections, setSections] = useState([]);
  const [loadingClasses, setLoadingClasses] = useState(false);
  const [loadingSections, setLoadingSections] = useState(false);
  const [loadingCreateSection, setLoadingCreateSection] = useState(false);
  const [newSectionName, setNewSectionName] = useState("");
  const [showNewSectionForm, setShowNewSectionForm] = useState(false);
  const [error, setError] = useState("");

  // Load sessions from localStorage on mount
  useEffect(() => {
    const storedSessions = JSON.parse(localStorage.getItem("activeSessions")) || [];
    setActiveSessions(storedSessions);
  }, []);

  // When a session is selected, load all classes for that session
  useEffect(() => {
    if (!selectedSession) {
      setClasses([]);
      setSelectedClass("");
      return;
    }
    const fetchClasses = async () => {
      try {
        setLoadingClasses(true);
        const res = await api.get(`/classes/all?session_id=${selectedSession}`);
        setClasses(res.data.classes);
      } catch (err) {
        console.error(err);
        setError(err.response?.data?.message || "Failed to fetch classes");
      } finally {
        setLoadingClasses(false);
      }
    };
    fetchClasses();
  }, [selectedSession]);

  // When a class is selected, load its sections
  useEffect(() => {
    if (!selectedClass) {
      setSections([]);
      return;
    }
    const fetchSections = async () => {
      try {
        setLoadingSections(true);
        const res = await api.get(`/section/${selectedClass}`);
        setSections(res.data.sections);
      } catch (err) {
        if (err.response?.status === 404) {
          setSections([]);
        } else {
          console.error(err);
          setError(err.response?.data?.message || "Failed to fetch sections");
        }
      } finally {
        setLoadingSections(false);
      }
    };
    fetchSections();
  }, [selectedClass]);

  // Handle session selection
  const handleSessionChange = (e) => {
    setSelectedSession(e.target.value);
    setSelectedClass("");
    setSections([]);
  };

  // Handle class selection
  const handleClassChange = (e) => {
    setSelectedClass(e.target.value);
  };

  // Delete a section
  const handleDeleteSection = async (id) => {
    if (!window.confirm("Are you sure you want to delete this section?")) return;
    try {
      await api.delete(`/section/${id}`);
      setSections((prev) => prev.filter((section) => section.id !== id));
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to delete section");
    }
  };

  // Create a new section
  const handleCreateSection = async (e) => {
    e.preventDefault();
    if (!newSectionName) return;
    try {
      setLoadingCreateSection(true);
      const payload = {
        class_id: selectedClass,
        session_id: selectedSession,
        name: newSectionName,
      };
      const res = await api.post("/section/", payload);
      setSections((prev) => [...prev, res.data.section]);
      setNewSectionName("");
      setShowNewSectionForm(false);
    } catch (err) {
      console.error(err);
      setError(err.response?.data?.message || "Failed to create section");
    } finally {
      setLoadingCreateSection(false);
    }
  };

  return (
    <SchoolAdminLayout>
    <div className="assign-section-container">
      <h2>Assign Section to Class</h2>
      {error && <p className="error">{error}</p>}

      {/* Session Selection */}
      <div className="form-group">
        <label htmlFor="session">Select Session:</label>
        <select id="session" value={selectedSession} onChange={handleSessionChange}>
          <option value="">-- Select Session --</option>
          {activeSessions.map((session) => (
            <option key={session.id} value={session.id}>
              {session.name}
            </option>
          ))}
        </select>
      </div>

      {/* Class Selection */}
      {selectedSession && (
        <div className="form-group">
          <label htmlFor="class">Select Class:</label>
          {loadingClasses ? (
            <Skeleton height={30} width={200} />
          ) : (
            <select id="class" value={selectedClass} onChange={handleClassChange}>
              <option value="">-- Select Class --</option>
              {classes.map((cls) => (
                <option key={cls.id} value={cls.id}>
                  {cls.name}
                </option>
              ))}
            </select>
          )}
        </div>
      )}

      {/* Sections List */}
      {selectedClass && (
        <div className="sections-container">
          <h3>Sections</h3>
          {loadingSections ? (
            <div>
              <Skeleton height={25} count={3} />
            </div>
          ) : sections.length === 0 ? (
            <p>No sections found for this class.</p>
          ) : (
            <ul className="sections-list">
              {sections.map((section) => (
                <li key={section.id} className="section-item">
                  <span>{section.name}</span>
                  <button className="delete-button" onClick={() => handleDeleteSection(section.id)}>
                    Delete
                  </button>
                </li>
              ))}
            </ul>
          )}

          {/* Add New Section */}
          <button onClick={() => setShowNewSectionForm((prev) => !prev)} className="add-section-button">
            {showNewSectionForm ? "Cancel" : "Add New Section"}
          </button>
          {showNewSectionForm && (
            <form onSubmit={handleCreateSection} className="new-section-form">
              <input
                type="text"
                placeholder="Section Name"
                value={newSectionName}
                onChange={(e) => setNewSectionName(e.target.value)}
                required
              />
              <button type="submit" disabled={loadingCreateSection}>
                {loadingCreateSection ? "Creating..." : "Create Section"}
              </button>
            </form>
          )}
        </div>
      )}
      
    </div>
    </SchoolAdminLayout>
    
  );
};

export default AssignSection;
