// src/pages/SchoolAdmin/Students/CreateStudent.jsx
import React from 'react';
import StudentForm from '../../../components/StudentForm';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';

const CreateStudent = () => {
  return (
    <SchoolAdminLayout>
      <h1>Create New Student</h1>
      <StudentForm mode="create" />
    </SchoolAdminLayout>
  );
};

export default CreateStudent;
