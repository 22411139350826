// utils/api.js
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const api = axios.create({
  baseURL: 'https://ubuddy-backend.onrender.com/api',
});

// Add a request interceptor to include the JWT token in headers
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const navigate = useNavigate();

    // If the error is a 401 Unauthorized (Token expired or invalid)
    if (error.response && error.response.status === 403) {
      // Clear stored user data
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('user');
      localStorage.removeItem('role');
      
      // Redirect to login page
      navigate('/login');
    }
    return Promise.reject(error);
  }
);

export default api;
