import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/Auth.css';

export default function SchoolAdminLogin() {
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const fetchAndSaveSessions = async (token) => {
    let retryCount = 0;
    const maxRetries = 3;

    while (retryCount < maxRetries) {
      try {
        const sessionResponse = await fetch('https://ubuddy-backend.onrender.com/api/sessions/active', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        if (!sessionResponse.ok) throw new Error('Failed to fetch active sessions');

        const sessionData = await sessionResponse.json();
        localStorage.setItem('activeSessions', JSON.stringify(sessionData));
        return true; // Return success status
      } catch (err) {
        console.error(`Attempt ${retryCount + 1} failed:`, err.message);
        retryCount++;
        await new Promise(resolve => setTimeout(resolve, 5000)); // Wait 5 seconds before retry
      }
    }
    throw new Error('Failed to fetch sessions after 3 attempts');
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      const loginResponse = await fetch('https://ubuddy-backend.onrender.com/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(credentials),
      });

      if (!loginResponse.ok) {
        throw new Error('Invalid email or password');
      }

      const loginData = await loginResponse.json();
      sessionStorage.setItem('token', loginData.token);
      sessionStorage.setItem('user', JSON.stringify(loginData.user));
      localStorage.setItem('role', loginData.user.role);

      // Wait for sessions to be fetched and saved before navigation
      await fetchAndSaveSessions(loginData.token);
      
      navigate('/school-admin/dashboard');
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="auth-container">
      <div className="auth-card">
        <h2>School Admin Portal</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="form-group">
            <label>Email</label>
            <input
              type="email"
              value={credentials.email}
              onChange={(e) => setCredentials({ ...credentials, email: e.target.value })}
              required
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              type="password"
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
              required
            />
          </div>
          <button type="submit" className="auth-button" disabled={loading}>
            {loading ? 'Logging in...' : 'Login'}
          </button>
        </form>
      </div>
    </div>
  );
}