// src/pages/SchoolAdmin/ClassSubjectsPage.jsx
import React, { useState, useEffect } from 'react';
import api from '../../api';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/ClassSubjectPage.css';

const ClassSubjectsPage = () => {
  const [activeSessions, setActiveSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [subjectSettings, setSubjectSettings] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const sessions = JSON.parse(localStorage.getItem('activeSessions')) || [];
    setActiveSessions(sessions);
  }, []);

  useEffect(() => {
    if (selectedSession) {
      const fetchClasses = async () => {
        try {
          setLoading(true);
          const res = await api.get(`/classes/all?session_id=${selectedSession}`);
          setClasses(res.data.classes);
        } catch (err) {
          setError('Failed to fetch classes');
        } finally {
          setLoading(false);
        }
      };
      fetchClasses();
    }
  }, [selectedSession]);

  useEffect(() => {
    if (selectedClass) {
      const fetchData = async () => {
        try {
          setLoading(true);
          // Fetch all subjects and current allocations
          const [subjectsRes, allocatedRes] = await Promise.all([
            api.get('/subject/'),
            api.get(`/class-subjects?class_id=${selectedClass}`)
          ]);

          const subjectsList = subjectsRes.data.subjects;
          const allocatedSubjects = allocatedRes.data.classSubjects;

          // Merge data
          const settings = {};
          subjectsList.forEach(sub => {
            const allocated = allocatedSubjects.find(a => a.subject_id === sub.id);
            settings[sub.id] = {
              is_allocated: !!allocated,
              is_compulsory: allocated?.is_compulsory || false,
              is_result_subject: allocated?.is_result_subject || false,
              is_daily_schedule: allocated?.is_daily_schedule || false,
              is_timetable: allocated?.is_timetable || false
            };
          });

          setSubjects(subjectsList);
          setSubjectSettings(settings);
        } catch (err) {
          setError('Failed to load data');
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [selectedClass]);

  const handleSettingChange = (subjectId, field, value) => {
    setSubjectSettings(prev => ({
      ...prev,
      [subjectId]: {
        ...prev[subjectId],
        [field]: value
      }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError('');
      setSuccess('');
  
      // Prepare payload with ALL subjects (including deallocated)
      const payload = {
        class_id: selectedClass,
        subjects: Object.entries(subjectSettings).map(([subjectId, settings]) => ({
          subject_id: subjectId,
          is_compulsory: settings.is_compulsory,
          is_result_subject: settings.is_result_subject,
          is_daily_schedule: settings.is_daily_schedule,
          is_timetable: settings.is_timetable,
          // Send allocation status explicitly
          is_allocated: settings.is_allocated
        }))
      };
  
      const res = await api.post('/class-subjects/create', payload);
      setSuccess(res.data.message);
  
      // Refresh data
      const [allocatedRes] = await Promise.all([
        api.get(`/class-subjects?class_id=${selectedClass}`)
      ]);
      
      // Update settings with fresh data
      const updatedSettings = {};
      subjects.forEach(sub => {
        const allocated = allocatedRes.data.classSubjects.find(
          a => a.subject_id === sub.id
        );
        updatedSettings[sub.id] = {
          is_allocated: !!allocated,
          is_compulsory: allocated?.is_compulsory || false,
          is_result_subject: allocated?.is_result_subject || false,
          is_daily_schedule: allocated?.is_daily_schedule || false,
          is_timetable: allocated?.is_timetable || false
        };
      });
      
      setSubjectSettings(updatedSettings);
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to save');
    } finally {
      setLoading(false);
    }
  };

  return (
    <SchoolAdminLayout>
      <div className="class-subjects-page">
        <h1>Manage Class Subjects</h1>
        {error && <div className="error">{error}</div>}
        {success && <div className="success">{success}</div>}

        <div className="selection-filters">
          <select value={selectedSession} onChange={e => setSelectedSession(e.target.value)}>
            <option value="">Select Session</option>
            {activeSessions.map(session => (
              <option key={session.id} value={session.id}>{session.name}</option>
            ))}
          </select>

          <select 
            value={selectedClass} 
            onChange={e => setSelectedClass(e.target.value)}
            disabled={!selectedSession}
          >
            <option value="">Select Class</option>
            {classes.map(cls => (
              <option key={cls.id} value={cls.id}>{cls.name}</option>
            ))}
          </select>
        </div>

        {selectedClass && (
          <div className="subjects-table-container">
            <table>
              <thead>
                <tr>
                  <th>Subject</th>
                  <th>Allocated</th>
                  <th>Compulsory</th>
                  <th>In Timetable</th>
                  <th>In Results</th>
                  <th>Daily Schedule</th>
                </tr>
              </thead>
              <tbody>
                {subjects.map(subject => (
                  <tr key={subject.id}>
                    <td>{subject.name} ({subject.code})</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subjectSettings[subject.id]?.is_allocated || false}
                        onChange={e => handleSettingChange(
                          subject.id, 
                          'is_allocated', 
                          e.target.checked
                        )}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subjectSettings[subject.id]?.is_compulsory || false}
                        onChange={e => handleSettingChange(
                          subject.id, 
                          'is_compulsory', 
                          e.target.checked
                        )}
                        disabled={!subjectSettings[subject.id]?.is_allocated}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subjectSettings[subject.id]?.is_timetable || false}
                        onChange={e => handleSettingChange(
                          subject.id, 
                          'is_timetable', 
                          e.target.checked
                        )}
                        disabled={!subjectSettings[subject.id]?.is_allocated}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subjectSettings[subject.id]?.is_result_subject || false}
                        onChange={e => handleSettingChange(
                          subject.id, 
                          'is_result_subject', 
                          e.target.checked
                        )}
                        disabled={!subjectSettings[subject.id]?.is_allocated}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={subjectSettings[subject.id]?.is_daily_schedule || false}
                        onChange={e => handleSettingChange(
                          subject.id, 
                          'is_daily_schedule', 
                          e.target.checked
                        )}
                        disabled={!subjectSettings[subject.id]?.is_allocated}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            
            <button 
              onClick={handleSubmit}
              disabled={loading}
              className="save-button"
            >
              {loading ? 'Saving...' : 'Save Changes'}
            </button>
          </div>
        )}
      </div>
    </SchoolAdminLayout>
  );
};

export default ClassSubjectsPage;