// pages/SuperAdmin/Schools.jsx
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FiEye, FiEdit, FiTrash2 } from 'react-icons/fi';
import '../../styles/ManageSchools.css';
import SuperAdminLayout from '../../components/SuperAdminLayout';

export default function ManageSchools() {
  const [schools, setSchools] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage] = useState(10);
  const [loadingSchools, setLoadingSchools] = useState(false);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    const fetchSchools = async () => {
      setLoadingSchools(true);
      setFetchError(null);
      try {
        const token = sessionStorage.getItem('token');
        const response = await fetch('https://ubuddy-backend.onrender.com/api/school/all', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        if (!response.ok) {
          throw new Error('Failed to fetch schools');
        }
        const data = await response.json();
        setSchools(data.schools);
      } catch (error) {
        console.error('Error fetching schools:', error);
        setFetchError(error.message);
      } finally {
        setLoadingSchools(false);
      }
    };

    fetchSchools();
  }, []);

  // Filter the schools based on the search term
  const filteredSchools = schools.filter(school =>
    school.school_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination calculations
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = filteredSchools.slice(indexOfFirstEntry, indexOfLastEntry);
  const totalPages = Math.ceil(filteredSchools.length / entriesPerPage);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  return (
    <SuperAdminLayout>
      <div className="page-content">
        <div className="controls-container">
          <div className="entries-selector">
            <span>Show</span>
            <select className="entries-dropdown" value={entriesPerPage} disabled>
              <option>10</option>
            </select>
            <span>entries</span>
          </div>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search by name..."
              className="search-input"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className="add-school-container">
          <Link to="/super-admin/schools/create" className="add-school-button">
            Add New School
          </Link>
        </div>

        {loadingSchools ? (
          <p>Loading schools...</p>
        ) : fetchError ? (
          <p className="error-message">{fetchError}</p>
        ) : (
          <div className="table-container">
            <table className="school-table">
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>School Name</th>
                  <th>City</th>
                  <th>Board</th>
                  <th>Website</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentEntries.map((school, index) => (
                  <tr key={school.school_code}>
                    <td>{index + 1}</td>
                    <td>{school.school_name}</td>
                    <td>{school.city}</td>
                    <td>{school.board}</td>
                    <td>{school.website}</td>
                    <td>
                      <div className="action-buttons">
                        <button className="action-button view-button">
                          <FiEye size={18} />
                        </button>
                        <button className="action-button edit-button">
                          <FiEdit size={18} />
                        </button>
                        <button className="action-button delete-button">
                          <FiTrash2 size={18} />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="pagination-container">
          <span className="entries-info">
            Showing {filteredSchools.length === 0 ? 0 : indexOfFirstEntry + 1} to {Math.min(indexOfLastEntry, filteredSchools.length)} of {filteredSchools.length} entries
          </span>
          <div className="pagination-controls">
            <button
              className="page-button"
              onClick={() => setCurrentPage(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="current-page">{currentPage}</span>
            <button
              className="page-button"
              onClick={() => setCurrentPage(Math.min(totalPages, currentPage + 1))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </SuperAdminLayout>
  );
}
