// src/components/SchoolAdminSidebar.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import '../styles/SchoolAdminSidebar.css';

const SchoolAdminSidebar = ({ isOpen }) => {
  const [isStudentMgmtOpen, setStudentMgmtOpen] = useState(false);

  const toggleStudentMgmt = () => {
    setStudentMgmtOpen(!isStudentMgmtOpen);
  };

  return (
    <aside className={`schooladmin-sidebar ${isOpen ? 'open' : 'closed'}`}>
      <nav className="sidebar-nav">
        <div className="sidebar-item">
          <button className="sidebar-link" onClick={toggleStudentMgmt}>
            <span>Student Management</span>
            {isStudentMgmtOpen ? <FiChevronUp /> : <FiChevronDown />}
          </button>
          {isStudentMgmtOpen && (
            <ul className="sub-menu">
             
              
              <li><Link to="/school-admin/student-managment/students">Manage Students</Link></li>
              <li><Link to="/school-admin/enrollment-details">Status</Link></li>
             
              <li><Link to="/school-admin/additional-subject-assignment">Assign Additional Subject</Link></li>
            </ul>
          )}
        </div>
        <div className="sidebar-item">
          <Link className="sidebar-link" to="/school-admin/result">Result</Link>
        </div>
        <div className="sidebar-item">
          <Link className="sidebar-link" to="/school-admin/syllabus">Syllabus</Link>
        </div>
      </nav>
    </aside>
  );
};

export default SchoolAdminSidebar;
