// pages/SuperAdmin/Dashboard.jsx
import SuperAdminLayout from '../../components/SuperAdminLayout';

import '../../styles/SuperAdminDashboard.css';

export default function SuperAdminDashboard() {
  return (
    <SuperAdminLayout>
      <div className="dashboard-content">
        <h1>Welcome to Ubuddy Admin Panel</h1>
        <div className="admin-stats">
          <div className="stat-item">
            <span className="stat-value">15</span>
            <span className="stat-label">Schools Managed</span>
          </div>
          <div className="stat-item">
            <span className="stat-value">245</span>
            <span className="stat-label">Active Users</span>
          </div>
        </div>
      </div>
    </SuperAdminLayout>
  );
}
