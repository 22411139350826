import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Updated import
import api from '../../api'; // Import your custom API utility
import SchoolAdminLayout from '../../components/SchoolAdminLayout'; // Layout import
import '../../styles/AssignSubjectSchool.css'; // Custom CSS for styling

const AssignSubjectSchool = () => {
  const [subjects, setSubjects] = useState([]);
  const [newSubject, setNewSubject] = useState({ name: '', code: '' });
  const [isLoading, setIsLoading] = useState(false); // State to track loading status
  const navigate = useNavigate(); // Replaced useHistory with useNavigate

  // Fetch subjects when the page loads
  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await api.get('/subject', {
          params: { school_code: 'SCHOOL_CODE' }, // Replace with dynamic school code
        });
        setSubjects(response.data.subjects);
      } catch (error) {
        console.error('Error fetching subjects:', error);
      }
    };

    fetchSubjects();
  }, []);

  // Handle subject creation
  const handleAddSubject = async () => {
    setIsLoading(true); // Set loading state to true when the request starts
    try {
      const response = await api.post('/subject/create', {
        school_code: 'SCHOOL_CODE', // Replace with dynamic school code
        subjects: [newSubject],
      });
      setSubjects((prevSubjects) => [...prevSubjects, ...response.data.subjects]);
      setNewSubject({ name: '', code: '' });
    } catch (error) {
      console.error('Error adding subject:', error);
    } finally {
      setIsLoading(false); // Set loading state to false when the request ends
    }
  };

  return (
    <SchoolAdminLayout>
      <div className="assign-subject-container">
        <h2 className="assign-subject-heading">Assign Subjects to School</h2>

        <div className="subject-list">
          <h3>Available Subjects</h3>
          <ul>
            {subjects.map((subject) => (
              <li key={subject.id} className="subject-item">
                {subject.name} ({subject.code})
              </li>
            ))}
          </ul>
        </div>

        <div className="add-subject-form">
          <h3>Add New Subject</h3>
          <input
            type="text"
            placeholder="Subject Name"
            value={newSubject.name}
            onChange={(e) => setNewSubject({ ...newSubject, name: e.target.value })}
            className="input-field"
          />
          <input
            type="text"
            placeholder="Subject Code"
            value={newSubject.code}
            onChange={(e) => setNewSubject({ ...newSubject, code: e.target.value })}
            className="input-field"
          />
          <button
            onClick={handleAddSubject}
            className="submit-button"
            disabled={isLoading} // Disable the button while loading
          >
            {isLoading ? 'Adding Subject...' : 'Add Subject'} {/* Button text based on loading state */}
          </button>
        </div>
      </div>
    </SchoolAdminLayout>
  );
};

export default AssignSubjectSchool;
