import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from '../api';
import '../styles/Students/StudentForm.css';

// Helper: formats an ISO date string to "YYYY-MM-DD"
const formatDate = (isoDate) => {
  if (!isoDate) return '';
  return isoDate.split('T')[0];
};

const StudentForm = ({ mode = 'create', student }) => {
  const { studentId } = useParams();
  const navigate = useNavigate();

  // Define only the expected keys for our form state.
  const initialFormState = {
    school_code: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    date_of_birth: '',
    gender: '',
    aadhar_number: '',
    sssmid: '',
    family_sssmid: '',
    apaar_id: '',
    pen: '',
    primary_contact: '',
    current_address: '',
    permanent_address: '',
    session_id: '',
    class_id: '',
    section_id: '', // will hold section id selected from dropdown
    roll_number: '',
    admission_type: '',
    admission_date: '',
    previous_class: '',
    previous_school: '',
  };

  const [formData, setFormData] = useState(initialFormState);
  const [familyMembers, setFamilyMembers] = useState([]); // Array for family details
  const [bankDetails, setBankDetails] = useState({
    account_holder: '',
    account_number: '',
    ifsc_code: '',
  });
  const [sessions, setSessions] = useState([]); // Sessions loaded from localStorage
  const [classes, setClasses] = useState([]);   // Classes for selected session
  const [sections, setSections] = useState([]);   // Sections for selected class
  const [loading, setLoading] = useState(false);
  const [loadingSections, setLoadingSections] = useState(false);
  const [error, setError] = useState('');

  // Load sessions from localStorage on mount.
  useEffect(() => {
    const storedSessions = JSON.parse(localStorage.getItem('activeSessions')) || [];
    setSessions(storedSessions);
  }, []);

  // In edit mode, prefill our form with data from the API.
  useEffect(() => {
    if (mode === 'edit' && student && Object.keys(student).length > 0) {
      console.log('Student prop received in StudentForm:', student);

      // Use the nested "student" property if available; otherwise, use the entire object.
      const personal = student.student ? student.student : student;
      // Use the first enrollment record if it exists.
      const enroll =
        student.enrollment && Array.isArray(student.enrollment) && student.enrollment.length > 0
          ? student.enrollment[0]
          : {};

      // Build a new object with only the expected keys.
      const prefill = {
        school_code: personal.school_code || '',
        first_name: personal.first_name || '',
        middle_name: personal.middle_name || '',
        last_name: personal.last_name || '',
        date_of_birth: personal.date_of_birth ? formatDate(personal.date_of_birth) : '',
        gender: personal.gender || '',
        aadhar_number: personal.aadhar_number || '',
        sssmid: personal.sssmid || '',
        family_sssmid: personal.family_sssmid || '',
        apaar_id: personal.apaar_id || '',
        pen: personal.pen || '',
        primary_contact: personal.primary_contact || '',
        current_address: personal.current_address || '',
        permanent_address: personal.permanent_address || '',
        session_id: enroll.session_id ? String(enroll.session_id) : '',
        class_id: enroll.class_id ? String(enroll.class_id) : '',
        section_id: enroll.section_id ? String(enroll.section_id) : '',
        roll_number: enroll.roll_number || '',
        admission_type: enroll.admission_type || '',
        admission_date: enroll.admission_date ? formatDate(enroll.admission_date) : '',
        previous_class: enroll.previous_class || '',
        previous_school: enroll.previous_school || '',
      };

      console.log('Prefill object:', prefill);
      setFormData(prefill);
      setFamilyMembers(student.family_details || []);
      setBankDetails(student.bank_details || { account_holder: '', account_number: '', ifsc_code: '' });

      // If a session_id is present, load the classes.
      if (prefill.session_id) {
        fetchClasses(prefill.session_id);
      }
    }
  }, [mode, student]);

  // Fetch classes based on the selected session.
  const fetchClasses = async (sessionId) => {
    try {
      setLoading(true);
      const res = await api.get(`classes/all?session_id=${sessionId}`);
      setClasses(res.data.classes);
      setError('');
    } catch (err) {
      setError(err.response?.data?.message || 'Failed to load classes');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Fetch sections based on the selected class.
  const fetchSections = async (classId) => {
    try {
      setLoadingSections(true);
      const res = await api.get(`/section/${classId}`);
      setSections(res.data.sections);
      setError('');
    } catch (err) {
      if (err.response?.status === 404) {
        setSections([]); // No sections available for this class
      } else {
        setError(err.response?.data?.message || 'Failed to load sections');
        console.error(err);
      }
    } finally {
      setLoadingSections(false);
    }
  };

  // When class_id changes, fetch sections.
  useEffect(() => {
    if (formData.class_id) {
      fetchSections(formData.class_id);
    } else {
      setSections([]);
    }
  }, [formData.class_id]);

  // Update formData on field change.
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (name === 'session_id') {
      fetchClasses(value);
      // Clear the class and section fields when session changes.
      setFormData((prev) => ({ ...prev, class_id: '', section_id: '' }));
      setSections([]);
    }
  };

  // Handle family member field changes.
  const handleFamilyChange = (index, field, value) => {
    const updated = [...familyMembers];
    updated[index] = { ...updated[index], [field]: value };
    setFamilyMembers(updated);
  };

  // Add a new family member.
  const addFamilyMember = () => {
    setFamilyMembers([...familyMembers, { relation: '', name: '', aadhar_number: '', occupation: '', annual_income: '' }]);
  };

  // Handle bank detail changes.
  const handleBankDetailChange = (e) => {
    const { name, value } = e.target;
    setBankDetails((prev) => ({ ...prev, [name]: value }));
  };

  // Handle form submission.
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.session_id || !formData.class_id) {
      setError('Session and Class selection are compulsory');
      return;
    }
    setLoading(true);
    try {
      // Construct payload using only our expected keys.
      const payload = {
        student_id: studentId,
        school_code: formData.school_code || undefined,
        first_name: formData.first_name,
        middle_name: formData.middle_name || null,
        last_name: formData.last_name,
        date_of_birth: formData.date_of_birth || null,
        gender: formData.gender,
        aadhar_number: formData.aadhar_number || null,
        sssmid: formData.sssmid || null,
        family_sssmid: formData.family_sssmid || null,
        apaar_id: formData.apaar_id || null,
        pen: formData.pen || null,
        primary_contact: formData.primary_contact,
        current_address: formData.current_address || null,
        permanent_address: formData.permanent_address || null,
        session_id: formData.session_id,
        class_id: formData.class_id,
        section_id: formData.section_id || null,
        roll_number: formData.roll_number || null,
        admission_type: formData.admission_type,
        admission_date: formData.admission_date || null,
        previous_class: formData.previous_class || null,
        previous_school: formData.previous_school || null,
        family_details: familyMembers.map((member) => ({
          relation: member.relation || null,
          name: member.name || null,
          aadhar_number: member.aadhar_number || null,
          occupation: member.occupation || null,
          annual_income: member.annual_income || null,
        })),
        bank_details: {
          account_holder: bankDetails.account_holder || null,
          account_number: bankDetails.account_number || null,
          ifsc_code: bankDetails.ifsc_code || null,
        },
      };

      if (mode === 'create') {
        await api.post('/student/create', payload);
      } else {
        await api.patch(`/student/${studentId}`, payload);
      }
      navigate('/school-admin/student-managment/students');
    } catch (err) {
      setError(err.response?.data?.message || `Failed to ${mode} student`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2>{mode === 'create' ? 'Add New Student' : 'Edit Student'}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleSubmit}>
        {/* Personal Information */}
        <fieldset>
          <legend>Personal Information</legend>
          <label>First Name:</label>
          <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
          <label>Middle Name:</label>
          <input type="text" name="middle_name" value={formData.middle_name} onChange={handleChange} />
          <label>Last Name:</label>
          <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
          <label>Date of Birth:</label>
          <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} required />
          <label>Gender:</label>
          <select name="gender" value={formData.gender} onChange={handleChange} required>
            <option value="">Select</option>
            <option value="M">Male</option>
            <option value="F">Female</option>
            <option value="O">Other</option>
          </select>
          <label>Aadhar Number:</label>
          <input type="text" name="aadhar_number" value={formData.aadhar_number} onChange={handleChange} />
          <label>SSSMID:</label>
          <input type="text" name="sssmid" value={formData.sssmid} onChange={handleChange} />
          <label>Family SSSMID:</label>
          <input type="text" name="family_sssmid" value={formData.family_sssmid} onChange={handleChange} />
          <label>APaar ID:</label>
          <input type="text" name="apaar_id" value={formData.apaar_id} onChange={handleChange} />
          <label>PEN:</label>
          <input type="text" name="pen" value={formData.pen} onChange={handleChange} />
          <label>Primary Contact:</label>
          <input type="text" name="primary_contact" value={formData.primary_contact} onChange={handleChange} required />
          <label>Current Address:</label>
          <textarea name="current_address" value={formData.current_address} onChange={handleChange} />
          <label>Permanent Address:</label>
          <textarea name="permanent_address" value={formData.permanent_address} onChange={handleChange} />
        </fieldset>

        {/* Enrollment Details */}
        <fieldset>
          <legend>Enrollment Details</legend>
          <label>Session:</label>
          <select name="session_id" value={formData.session_id} onChange={handleChange} required>
            <option value="">Select Session</option>
            {sessions.map((s) => (
              <option key={s.id} value={s.id}>
                {s.name}
              </option>
            ))}
          </select>
          <label>Class:</label>
          <select name="class_id" value={formData.class_id} onChange={handleChange} required>
            <option value="">Select Class</option>
            {classes.map((c) => (
              <option key={c.id} value={c.id}>
                {c.name}
              </option>
            ))}
          </select>
          <label>Section:</label>
          {loadingSections ? (
            <p>Loading sections...</p>
          ) : (
            <>
              {sections.length > 0 ? (
                <select name="section_id" value={formData.section_id} onChange={handleChange} required>
                  <option value="">Select Section</option>
                  {sections.map((section) => (
                    <option key={section.id} value={section.id}>
                      {section.name}
                    </option>
                  ))}
                </select>
              ) : (
                <p>No section available for this class</p>
              )}
            </>
          )}
          <label>Roll Number:</label>
          <input type="text" name="roll_number" value={formData.roll_number} onChange={handleChange} />
          <label>Admission Type:</label>
          <select name="admission_type" value={formData.admission_type} onChange={handleChange} required>
            <option value="">Select</option>
            <option value="RTE">RTE</option>
            <option value="Non-RTE">Non-RTE</option>
          </select>
          <label>Admission Date:</label>
          <input type="date" name="admission_date" value={formData.admission_date} onChange={handleChange} required />
          <label>Previous Class:</label>
          <input type="text" name="previous_class" value={formData.previous_class} onChange={handleChange} />
          <label>Previous School:</label>
          <input type="text" name="previous_school" value={formData.previous_school} onChange={handleChange} />
        </fieldset>

        {/* Family Details */}
        <fieldset>
          <legend>Family Details</legend>
          {familyMembers.map((member, index) => (
            <div key={index} className="family-member" style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
              <label>Relation:</label>
              <select value={member.relation || ''} onChange={(e) => handleFamilyChange(index, 'relation', e.target.value)} required>
                <option value="">Select Relation</option>
                <option value="father">Father</option>
                <option value="mother">Mother</option>
                <option value="guardian">Guardian</option>
              </select>
              <label>Name:</label>
              <input type="text" value={member.name || ''} onChange={(e) => handleFamilyChange(index, 'name', e.target.value)} required />
              <label>Aadhar Number:</label>
              <input type="text" value={member.aadhar_number || ''} onChange={(e) => handleFamilyChange(index, 'aadhar_number', e.target.value)} />
              <label>Occupation:</label>
              <input type="text" value={member.occupation || ''} onChange={(e) => handleFamilyChange(index, 'occupation', e.target.value)} />
              <label>Annual Income:</label>
              <input type="text" value={member.annual_income || ''} onChange={(e) => handleFamilyChange(index, 'annual_income', e.target.value)} />
            </div>
          ))}
          <button type="button" onClick={addFamilyMember}>＋ Add Family Member</button>
        </fieldset>

        {/* Bank Details */}
        <fieldset>
          <legend>Bank Details</legend>
          <label>Account Holder:</label>
          <input type="text" name="account_holder" value={bankDetails.account_holder} onChange={handleBankDetailChange} />
          <label>Account Number:</label>
          <input type="text" name="account_number" value={bankDetails.account_number} onChange={handleBankDetailChange} />
          <label>IFSC Code:</label>
          <input type="text" name="ifsc_code" value={bankDetails.ifsc_code} onChange={handleBankDetailChange} />
        </fieldset>

        <button type="submit" disabled={loading}>
          {loading ? 'Saving...' : 'Submit'}
        </button>
      </form>
    </div>
  );
};

export default StudentForm;
