import React, { useState, useEffect } from 'react';
import api from '../../api';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/ClassesPage.css';

const ClassesPage = () => {
    const [sessions, setSessions] = useState([]);
    const [selectedSession, setSelectedSession] = useState(null);
    const [classes, setClasses] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [className, setClassName] = useState('');
    const [promoteTo, setPromoteTo] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        const loadSessions = () => {
            const storedSessions = JSON.parse(localStorage.getItem('activeSessions')) || [];
            setSessions(storedSessions);
        };
        loadSessions();
    }, []);

    const fetchClasses = async (sessionId) => {
        try {
            setLoading(true);
            // Changed to GET with query parameter
            const response = await api.get(`classes/all?session_id=${sessionId}`);
            setClasses(response.data.classes);
            setError('');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to load classes');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoading(true);
            const response = await api.post('classes/create', {
                session_id: selectedSession,
                name: className,
                promote_to: promoteTo || null // Send null if empty
            });
            setClasses([...classes, response.data.class]);
            setShowForm(false);
            setClassName('');
            setPromoteTo('');
        } catch (err) {
            setError(err.response?.data?.message || 'Failed to create class. ' + (err.response?.data?.detail || ''));
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <SchoolAdminLayout>
            <div className="classes-container">
                {!selectedSession ? (
                    <div className="session-selector">
                        <h2>Select Academic Session</h2>
                        <select
                            value=""
                            onChange={(e) => {
                                setSelectedSession(e.target.value);
                                fetchClasses(e.target.value);
                            }}
                            disabled={loading}
                        >
                            <option value="">Choose a session</option>
                            {sessions.map(session => (
                                <option key={session.id} value={session.id}>
                                    {session.name}
                                </option>
                            ))}
                        </select>
                    </div>
                ) : (
                    <div className="classes-management">
                        <div className="header">
                            <h2>{sessions.find(s => s.id === selectedSession)?.name} Classes</h2>
                            <button 
                                onClick={() => setShowForm(true)}
                                className="add-button"
                                disabled={loading}
                            >
                                ＋ Add Class
                            </button>
                        </div>

                        {error && <div className="error-banner">{error}</div>}

                        {showForm && (
                            <form onSubmit={handleSubmit} className="class-form">
                                <input
                                    type="text"
                                    placeholder="Class Name (e.g., Grade 1)"
                                    value={className}
                                    onChange={(e) => setClassName(e.target.value)}
                                    required
                                />
                                <input
                                    type="text"
                                    placeholder="Promote to (optional)"
                                    value={promoteTo}
                                    onChange={(e) => setPromoteTo(e.target.value)}
                                />
                                <div className="form-actions">
                                    <button type="button" onClick={() => setShowForm(false)}>
                                        Cancel
                                    </button>
                                    <button type="submit" disabled={loading}>
                                        {loading ? 'Saving...' : 'Save Class'}
                                    </button>
                                </div>
                            </form>
                        )}

                        <div className="classes-list">
                            {classes.length > 0 ? (
                                classes.map(cls => (
                                    <div key={cls.id} className="class-card">
                                        <h3>{cls.name}</h3>
                                        {cls.promote_to && (
                                            <p>Promotes to: {cls.promote_to}</p>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="empty-state">
                                    No classes found for selected session
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </SchoolAdminLayout>
    );
};

export default ClassesPage;