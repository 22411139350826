// src/pages/SchoolAdmin/ExamAndGrading.jsx
import React, { useState, useEffect } from 'react';
import api from '../../api';
import SchoolAdminLayout from '../../components/SchoolAdminLayout';
import '../../styles/GradePage.css';

const LoaderButton = ({ isLoading, children, ...props }) => (
  <button
    {...props}
    disabled={isLoading}
    className={`bg-blue-600 text-white px-4 py-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
  >
    {isLoading ? 'Loading...' : children}
  </button>
);

const ExamTermForm = ({ sessionId, classId, fetchExamTerms }) => {
  const [termName, setTermName] = useState('');
  const [marksScheme, setMarksScheme] = useState('single'); // 'single' or 'dual'
  const [theoryMarks, setTheoryMarks] = useState('');
  const [practicalMarks, setPracticalMarks] = useState('');
  const [component1Label, setComponent1Label] = useState('Theory'); // Label for theory marks
  const [component2Label, setComponent2Label] = useState('Practical'); // Label for practical marks
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const payload = {
        session_id: sessionId,
        term_name: termName,
        class_id: classId,
        marks_scheme: marksScheme,
        theory_marks: parseFloat(theoryMarks),
        // For single scheme, we send practical_marks as null
        practical_marks: marksScheme === 'dual' ? parseFloat(practicalMarks) : null,
        component_1_label: component1Label,
        component_2_label: marksScheme === 'dual' ? component2Label : null,
      };

      await api.post('/grading/exam-terms', payload);
      setMessage('Exam term created successfully');
      // Reset form fields
      setTermName('');
      setTheoryMarks('');
      setPracticalMarks('');
      setComponent1Label('Theory');
      setComponent2Label('Practical');
      fetchExamTerms();
    } catch (err) {
      console.error(err);
      setMessage(err.response?.data?.message || 'Error creating exam term');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Create Exam Term</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Term Name</label>
          <input
            type="text"
            value={termName}
            onChange={(e) => setTermName(e.target.value)}
            placeholder="Enter Term Name"
            required
          />
        </div>
        <div className="form-group">
          <label>Marks Scheme</label>
          <select value={marksScheme} onChange={(e) => setMarksScheme(e.target.value)} required>
            <option value="single">Single</option>
            <option value="dual">Dual</option>
          </select>
        </div>
        <div className="form-group">
          <label>Theory Marks (Max)</label>
          <input
            type="number"
            value={theoryMarks}
            onChange={(e) => setTheoryMarks(e.target.value)}
            placeholder="Enter max Theory Marks"
            required
          />
        </div>
        {marksScheme === 'dual' ? (
          <>
            <div className="form-group">
              <label>Practical Marks (Max)</label>
              <input
                type="number"
                value={practicalMarks}
                onChange={(e) => setPracticalMarks(e.target.value)}
                placeholder="Enter max Practical Marks"
                required
              />
            </div>
            <div className="form-group">
              <label>Theory Label</label>
              <input
                type="text"
                value={component1Label}
                onChange={(e) => setComponent1Label(e.target.value)}
                placeholder="Enter label for theory marks"
                required
              />
            </div>
            <div className="form-group">
              <label>Practical Label</label>
              <input
                type="text"
                value={component2Label}
                onChange={(e) => setComponent2Label(e.target.value)}
                placeholder="Enter label for practical marks"
                required
              />
            </div>
          </>
        ) : (
          <div className="form-group">
            <label>Theory Label</label>
            <input
              type="text"
              value={component1Label}
              onChange={(e) => setComponent1Label(e.target.value)}
              placeholder="Enter label for theory marks"
              required
            />
          </div>
        )}
        <LoaderButton isLoading={isLoading} type="submit">
          Submit
        </LoaderButton>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

const GradingScaleForm = ({ sessionId, fetchGradingScales }) => {
  const [scales, setScales] = useState([{ min_marks: '', max_marks: '', grade: '' }]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleScaleChange = (index, field, value) => {
    const updatedScales = [...scales];
    updatedScales[index][field] = value;
    setScales(updatedScales);
  };

  const addScale = () => {
    setScales([...scales, { min_marks: '', max_marks: '', grade: '' }]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await api.post('/grading/grading-scales', {
        session_id: sessionId,
        scales: scales.map((scale) => ({
          min_marks: parseFloat(scale.min_marks),
          max_marks: parseFloat(scale.max_marks),
          grade: scale.grade,
        })),
      });
      setMessage('Grading scale created successfully');
      setScales([{ min_marks: '', max_marks: '', grade: '' }]);
      fetchGradingScales();
    } catch (err) {
      console.error(err);
      setMessage(err.response?.data?.message || 'Error creating grading scale');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2 className="form-title">Create Grading Scale</h2>
      <form onSubmit={handleSubmit}>
        {scales.map((scale, index) => (
          <div key={index} className="scale-group">
            <input
              type="number"
              value={scale.min_marks}
              onChange={(e) => handleScaleChange(index, 'min_marks', e.target.value)}
              placeholder="Min Marks"
              required
            />
            <input
              type="number"
              value={scale.max_marks}
              onChange={(e) => handleScaleChange(index, 'max_marks', e.target.value)}
              placeholder="Max Marks"
              required
            />
            <input
              type="text"
              value={scale.grade}
              onChange={(e) => handleScaleChange(index, 'grade', e.target.value)}
              placeholder="Grade"
              required
            />
          </div>
        ))}
        <button type="button" onClick={addScale} className="add-scale-btn">
          Add Another Scale
        </button>
        <LoaderButton isLoading={isLoading} type="submit">
          Submit
        </LoaderButton>
        {message && <p className="message">{message}</p>}
      </form>
    </div>
  );
};

const ExamAndGrading = () => {
  const [activeSessions, setActiveSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState('');
  const [classes, setClasses] = useState([]);
  const [selectedClass, setSelectedClass] = useState('');
  const [examTerms, setExamTerms] = useState([]);
  const [gradingScales, setGradingScales] = useState([]);

  const fetchClasses = async () => {
    try {
      const response = await api.get(`/classes/all?session_id=${selectedSession}`);
      setClasses(response.data.classes);
      if (response.data.classes.length > 0) {
        setSelectedClass(response.data.classes[0].id);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fetchExamTerms = async () => {
    if (!selectedSession || !selectedClass) return;
    try {
      const response = await api.get(
        `/grading/exam-terms?session_id=${selectedSession}&class_id=${selectedClass}`
      );
      setExamTerms(response.data.exam_terms);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGradingScales = async () => {
    try {
      const response = await api.get(`/grading/grading-scales?session_id=${selectedSession}`);
      setGradingScales(response.data.grading_scales);
    } catch (err) {
      console.error(err);
    }
  };

  const deleteExamTerm = async (id) => {
    try {
      await api.delete(`/grading/exam-terms/${id}`);
      fetchExamTerms();
    } catch (err) {
      console.error(err);
    }
  };

  const deleteGradingScale = async (id) => {
    try {
      await api.delete(`/grading/grading-scales/${id}`);
      fetchGradingScales();
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const sessions = JSON.parse(localStorage.getItem('activeSessions') || '[]');
    setActiveSessions(sessions);
    if (sessions.length > 0) {
      setSelectedSession(sessions[0].id);
    }
  }, []);

  useEffect(() => {
    if (selectedSession) {
      fetchClasses();
      fetchGradingScales();
    }
  }, [selectedSession]);

  useEffect(() => {
    if (selectedClass) {
      fetchExamTerms();
    }
  }, [selectedClass]);

  return (
    <SchoolAdminLayout>
      <div className="page-container">
        <h1 className="page-title">Exam Term and Grading Scale Setup</h1>
        {/* Session selection */}
        <div className="session-selector">
          <label>Select Session</label>
          <select value={selectedSession || ''} onChange={(e) => setSelectedSession(e.target.value)}>
            {activeSessions.map((session) => (
              <option key={session.id} value={session.id}>
                {session.name}
              </option>
            ))}
          </select>
        </div>
        {/* Class selection */}
        <div className="class-selector">
          <label>Select Class</label>
          <select value={selectedClass || ''} onChange={(e) => setSelectedClass(e.target.value)}>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.name}
              </option>
            ))}
          </select>
        </div>
        {/* Exam Term Form */}
        <ExamTermForm sessionId={selectedSession} classId={selectedClass} fetchExamTerms={fetchExamTerms} />
        {/* Grading Scale Form */}
        <GradingScaleForm sessionId={selectedSession} fetchGradingScales={fetchGradingScales} />

        <div className="list-container">
          <h2>Existing Exam Terms</h2>
          <ul>
            {examTerms.map((term) => (
              <li key={term.id}>
                {term.term_name} - {term.marks_scheme === 'single' ? term.theory_marks : `${term.theory_marks} / ${term.practical_marks}`}
                <button onClick={() => deleteExamTerm(term.id)} className="delete-btn">
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>

        <div className="list-container">
          <h2>Existing Grading Scales</h2>
          <ul>
            {gradingScales.map((scale) => (
              <li key={scale.id}>
                {scale.min_marks} - {scale.max_marks}: {scale.grade}
                <button onClick={() => deleteGradingScale(scale.id)} className="delete-btn">
                  Delete
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </SchoolAdminLayout>
  );
};

export default ExamAndGrading;
