// src/components/StudentDetailView.jsx
import React from 'react';
import '../styles/Students/StudentDetailView.css';
const DetailItem = ({ label, value }) => (
  <div className="detail-item">
    <strong>{label}:</strong> {value || 'N/A'}
  </div>
);

const StudentDetailView = ({ student }) => {
  return (
    <div className="student-detail-view">
      <h2>Student Full Details</h2>
      <div className="personal-info">
        <h3>Personal Information</h3>
        <DetailItem
          label="Full Name"
          value={`${student.student.first_name} ${student.student.middle_name} ${student.student.last_name}`}
        />
        <DetailItem label="Date of Birth" value={student.student.date_of_birth} />
        <DetailItem label="Gender" value={student.student.gender} />
        <DetailItem label="Aadhar Number" value={student.student.aadhar_number} />
        <DetailItem label="Primary Contact" value={student.student.primary_contact} />
        <DetailItem label="Current Address" value={student.student.current_address} />
        <DetailItem label="Permanent Address" value={student.student.permanent_address} />
      </div>
      <div className="enrollment-info">
        <h3>Enrollment Information</h3>
        {student.enrollment &&
          student.enrollment.map((enroll, index) => (
            <div key={index}>
              <DetailItem label="Session ID" value={enroll.session_id} />
              <DetailItem label="Class name" value={enroll.class_name} />
              <DetailItem label="Section name" value={enroll.section_name } />
              <DetailItem label="Roll Number" value={enroll.roll_number} />
              <DetailItem label="Admission Type" value={enroll.admission_type} />
              <DetailItem label="Admission Date" value={enroll.admission_date} />
              <DetailItem label="Previous Class" value={enroll.previous_class} />
              <DetailItem label="Previous School" value={enroll.previous_school} />
            </div>
          ))}
      </div>
      <div className="family-info">
        <h3>Family Members</h3>
        {student.family_details &&
          student.family_details.map((member, index) => (
            <div key={index}>
              <DetailItem label="Relation" value={member.relation} />
              <DetailItem label="Name" value={member.name} />
              <DetailItem label="Aadhar Number" value={member.aadhar_number} />
              <DetailItem label="Occupation" value={member.occupation} />
              <DetailItem label="Annual Income" value={member.annual_income} />
            </div>
          ))}
      </div>
      {student.bank_details && (
        <div className="bank-info">
          <h3>Bank Details</h3>
          <DetailItem label="Account Holder" value={student.bank_details.account_holder} />
          <DetailItem label="Account Number" value={student.bank_details.account_number} />
          <DetailItem label="IFSC Code" value={student.bank_details.ifsc_code} />
        </div>
      )}
    </div>
  );
};

export default StudentDetailView;
