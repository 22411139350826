// components/SuperAdminHeader.jsx
import React from 'react';
import { FiMenu, FiUser, FiLogOut } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import '../styles/SuperAdminHeader.css';

export default function SuperAdminHeader({ toggleSidebar }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear tokens/roles as needed
    sessionStorage.removeItem('token');
    localStorage.removeItem('role');
    navigate('/login');
  };

  return (
    <header className="superadmin-header">
      <div className="header-left">
        <button className="menu-toggle" onClick={toggleSidebar}>
          <FiMenu size={24} />
        </button>
        <h1 className="header-title">Super Admin</h1>
      </div>
      <div className="header-right">
        <button className="profile-button">
          <FiUser size={24} />
        </button>
        <button className="logout-button" onClick={handleLogout}>
          <FiLogOut size={24} />
        </button>
      </div>
    </header>
  );
}
