// src/pages/SchoolAdmin/Students/EditStudent.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import StudentForm from '../../../components/StudentForm';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';

const EditStudent = () => {
  const { studentId } = useParams();
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const fetchStudentDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/student/${studentId}`);
      setStudentData(response.data.student);
    } catch (err) {
      setError('Error loading student details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [studentId]);

  if (loading) return <div>Loading student details...</div>;
  if (error) return <div>{error}</div>;
  if (!studentData) return <div>No student data found</div>;

  return (
    <SchoolAdminLayout>
      <h1>Edit Student</h1>
      <StudentForm mode="edit" student={studentData} />
    </SchoolAdminLayout>
  );
};

export default EditStudent;
