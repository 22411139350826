// components/SuperAdminSidebar.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { FiHome, FiList } from 'react-icons/fi';
import '../styles/SuperAdminSidebar.css';

export default function SuperAdminSidebar({ isOpen }) {
  return (
    <aside className={`superadmin-sidebar ${isOpen ? 'open' : 'closed'}`}>
      <div className="sidebar-header">
        <h2>Menu</h2>
      </div>
      <nav className="sidebar-nav">
        <Link to="/super-admin/dashboard" className="nav-link">
          <FiHome size={20} />
          <span>Dashboard</span>
        </Link>
        <Link to="/super-admin/schools" className="nav-link">
          <FiList size={20} />
          <span>Manage Schools</span>
        </Link>
      </nav>
    </aside>
  );
}
