// pages/SuperAdmin/CreateSchool.jsx
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuperAdminLayout from '../../components/SuperAdminLayout';
import '../../styles/CreateSchool.css';

export default function CreateSchool() {
  const [formData, setFormData] = useState({
    school_name: '',
    school_code: '',
    board: '',
    medium: '',
    contact_number: '',
    email: '',
    address: '',
    city: '',
    state: '',
    pincode: '',
    website: '',
    dice_code: '',
    admin_password: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const token = sessionStorage.getItem('token');
      const response = await fetch('https://ubuddy-backend.onrender.com/api/school/create', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        if (response.status === 403) {
          throw new Error('Unauthorized: Only Super Admin can create schools');
        }
        throw new Error('Failed to create school');
      }
      const data = await response.json();
      setSuccessMessage(`${data.message} - School Code: ${data.schoolCode}`);
      // Optionally, navigate back to Manage Schools after a delay:
      // setTimeout(() => navigate('/super-admin/schools'), 2000);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SuperAdminLayout>
      <div className="create-school-container">
        <div className="create-school-card">
          <h2>Create New School</h2>
          {error && <p className="error-message">{error}</p>}
          {successMessage && <p className="success-message">{successMessage}</p>}
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>School Name</label>
              <input 
                type="text" 
                name="school_name" 
                value={formData.school_name} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>School Code</label>
              <input 
                type="text" 
                name="school_code" 
                value={formData.school_code} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Board</label>
              <input 
                type="text" 
                name="board" 
                value={formData.board} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Medium</label>
              <input 
                type="text" 
                name="medium" 
                value={formData.medium} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Contact Number</label>
              <input 
                type="text" 
                name="contact_number" 
                value={formData.contact_number} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Admin Email</label>
              <input 
                type="email" 
                name="email" 
                value={formData.email} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Address</label>
              <textarea 
                name="address" 
                value={formData.address} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>City</label>
              <input 
                type="text" 
                name="city" 
                value={formData.city} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>State</label>
              <input 
                type="text" 
                name="state" 
                value={formData.state} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Pincode</label>
              <input 
                type="text" 
                name="pincode" 
                value={formData.pincode} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Website</label>
              <input 
                type="text" 
                name="website" 
                value={formData.website} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Dice Code</label>
              <input 
                type="text" 
                name="dice_code" 
                value={formData.dice_code} 
                onChange={handleChange} 
                required 
              />
            </div>
            <div className="form-group">
              <label>Admin Password</label>
              <input 
                type="password" 
                name="admin_password" 
                value={formData.admin_password} 
                onChange={handleChange} 
                required 
              />
            </div>
            <button type="submit" disabled={loading}>
              {loading ? 'Creating...' : 'Create School'}
            </button>
          </form>
        </div>
      </div>
    </SuperAdminLayout>
  );
}
