import React, { useState, useEffect } from 'react';
import api from '../../../api';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';
import '../../../styles/Students/EnrollmentDetails.css';

const EnrollmentDetails = () => {
  // Data and loading state
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filter states
  const [searchQuery, setSearchQuery] = useState('');
  const [statusFilter, setStatusFilter] = useState('all');
  const [classFilter, setClassFilter] = useState('all');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10;

  // Modal state for updating status
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [newStatus, setNewStatus] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState(null);

  // Fetch enrollment details from API
  const fetchStudents = async () => {
    try {
      setLoading(true);
      const response = await api.get('student/enrollment-details');
      setStudents(response.data.students);
    } catch (err) {
      setError('Error loading enrollment details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudents();
  }, []);

  // Filter the students list based on search and filter options
  const filteredStudents = students.filter((student) => {
    const fullName = `${student.first_name} ${student.last_name}`.toLowerCase();
    const rollNumber = student.roll_number ? student.roll_number.toString() : '';
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch = fullName.includes(searchLower) || rollNumber.includes(searchLower);

    const matchesStatus = statusFilter === 'all' || student.status === statusFilter;
    const matchesClass = classFilter === 'all' || student.class_name === classFilter;

    return matchesSearch && matchesStatus && matchesClass;
  });

  // Pagination calculations
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);
  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  // Get distinct class names for the class filter
  const classOptions = Array.from(new Set(students.map((student) => student.class_name))).filter(Boolean);

  // Open the modal for a given student (only if not terminated)
  const openStatusModal = (student) => {
    setSelectedStudent(student);
    setModalError(null);
    // Determine allowed new statuses based on the current status:
    // - For "active" students, only "inactive" is allowed.
    // - For "inactive" students, allowed statuses are "active" and "terminated".
    if (student.status === 'active') {
      setNewStatus('inactive');
    } else if (student.status === 'inactive') {
      setNewStatus('active'); // default to active; admin can change it in the dropdown.
    }
    setStatusModalOpen(true);
  };

  // Close the modal and reset its state
  const closeStatusModal = () => {
    setStatusModalOpen(false);
    setSelectedStudent(null);
    setNewStatus('');
    setModalError(null);
  };

  // Submit the new status via API
  const handleStatusUpdate = async () => {
    if (!selectedStudent) return;
    try {
      setModalLoading(true);
      await api.patch(`/student/${selectedStudent.student_id}/status`, { status: newStatus });
      // Refresh list after update
      await fetchStudents();
      closeStatusModal();
    } catch (err) {
      setModalError(err.response?.data?.message || 'Error updating student status');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  return (
    <SchoolAdminLayout>
      <div className="enrollment-container">
        <h1>Enrolled Students</h1>

        {/* Filters */}
        <div className="filters">
          <div className="filter-item">
            <input
              type="text"
              placeholder="Search by name or roll number..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1);
              }}
            />
          </div>
          <div className="filter-item">
            <select
              value={statusFilter}
              onChange={(e) => {
                setStatusFilter(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="all">All Statuses</option>
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
              <option value="terminated">Terminated</option>
            </select>
          </div>
          <div className="filter-item">
            <select
              value={classFilter}
              onChange={(e) => {
                setClassFilter(e.target.value);
                setCurrentPage(1);
              }}
            >
              <option value="all">All Classes</option>
              {classOptions.map((cls, index) => (
                <option key={index} value={cls}>
                  {cls}
                </option>
              ))}
            </select>
          </div>
        </div>

        {loading ? (
          <p>Loading enrollment details...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : (
          <>
            <table className="enrollment-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Roll Number</th>
                  <th>Class</th>
                  <th>Status</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.map((student) => (
                  <tr key={student.student_id}>
                    <td>
                      {student.first_name} {student.last_name}
                    </td>
                    <td>{student.roll_number || '-'}</td>
                    <td>{student.class_name}</td>
                    <td className={`status ${student.status}`}>{student.status}</td>
                    <td>
                      <button
                        className="action-btn"
                        onClick={() => openStatusModal(student)}
                        disabled={student.status === 'terminated'}
                      >
                        Change Status
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {/* Pagination */}
            {totalPages > 1 && (
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </>
        )}

        {/* Status Update Modal */}
        {statusModalOpen && selectedStudent && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>Update Student Status</h2>
              <p>
                <strong>Student:</strong> {selectedStudent.first_name} {selectedStudent.last_name} (ID:{' '}
                {selectedStudent.student_id})
              </p>
              <p>
                <strong>Current Status:</strong> {selectedStudent.status}
              </p>
              <div className="modal-field">
                <label>New Status:</label>
                <select value={newStatus} onChange={(e) => setNewStatus(e.target.value)}>
                  {selectedStudent.status === 'active' && <option value="inactive">Inactive</option>}
                  {selectedStudent.status === 'inactive' && (
                    <>
                      <option value="active">Active</option>
                      <option value="terminated">Terminated</option>
                    </>
                  )}
                </select>
              </div>
              {modalError && <p className="error">{modalError}</p>}
              <div className="modal-buttons">
                <button onClick={handleStatusUpdate} disabled={modalLoading}>
                  {modalLoading ? 'Updating...' : 'Update'}
                </button>
                <button onClick={closeStatusModal} className="cancel-button" disabled={modalLoading}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </SchoolAdminLayout>
  );
};

export default EnrollmentDetails;
