
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';
import StudentDetailView from '../../../components/StudentDetailView';
import '../../../styles/Students/StudentDetailView.css';

const StudentDetail = () => {
  const { studentId } = useParams();
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchStudentDetails = async () => {
    try {
      setLoading(true);
      const response = await api.get(`/student/${studentId}`);
      console.log(response.data);
      setStudent(response.data.student);
    } catch (err) {
      setError('Error loading student details');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudentDetails();
  }, [studentId]);

  if (loading) return <div>Loading student details...</div>;
  if (error) return <div>{error}</div>;
  if (!student) return <div>No student data found</div>;

  return (
    <SchoolAdminLayout>
      <h1>Student Details</h1>
      <StudentDetailView student={student} />
    </SchoolAdminLayout>
  );
};

export default StudentDetail;
