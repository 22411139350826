import React, { useState, useEffect } from 'react';
import api from '../../../api';
import SchoolAdminLayout from '../../../components/SchoolAdminLayout';
import '../../../styles/Students/Additional.css';

const AdditionalSubjectsAssignment = () => {
  // States for students
  const [students, setStudents] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filters: search by name/roll, class filter
  const [searchQuery, setSearchQuery] = useState('');
  const [classFilter, setClassFilter] = useState('all');

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const studentsPerPage = 10;

  // Modal state for assigning additional subject
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [availableSubjects, setAvailableSubjects] = useState([]);
  const [selectedSubjectId, setSelectedSubjectId] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const [modalError, setModalError] = useState('');

  // Session selection state
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  const [sessions, setSessions] = useState([]);

  // Fetch active sessions from local storage
  useEffect(() => {
    const activeSessions = JSON.parse(localStorage.getItem('activeSessions')) || [];
    setSessions(activeSessions);
    if (activeSessions.length > 0) {
      setSelectedSessionId(activeSessions[0].id); // Default to the first session
    }
  }, []);

  // Fetch all enrolled students from the API and filter for active status
  const fetchStudents = async () => {
    if (!selectedSessionId) return;

    try {
      setLoading(true);
      const response = await api.post('student/enrollment-details', {
        
        session_id: selectedSessionId,
      });
      // Filter active students only on the frontend
      const activeStudents = response.data.students.filter(student => student.status === 'active');
      setStudents(activeStudents);
    } catch (err) {
      setError('Error loading students');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedSessionId) {
      fetchStudents();
    }
  }, [selectedSessionId]);

  // Compute distinct class options from the student data
  const classOptions = Array.from(new Set(students.map(student => student.class_name))).filter(Boolean);

  // Filter students based on search query and class filter
  const filteredStudents = students.filter(student => {
    const fullName = `${student.first_name} ${student.last_name}`.toLowerCase();
    const roll = student.roll_number ? student.roll_number.toString() : '';
    const searchLower = searchQuery.toLowerCase();
    const matchesSearch = fullName.includes(searchLower) || roll.includes(searchLower);
    const matchesClass = classFilter === 'all' || student.class_name === classFilter;
    return matchesSearch && matchesClass;
  });

  // Pagination calculations
  const indexOfLastStudent = currentPage * studentsPerPage;
  const indexOfFirstStudent = indexOfLastStudent - studentsPerPage;
  const currentStudents = filteredStudents.slice(indexOfFirstStudent, indexOfLastStudent);
  const totalPages = Math.ceil(filteredStudents.length / studentsPerPage);

  // Open the modal and fetch available non-compulsory subjects for the student's class,
  // filtering out subjects already assigned.
  const openModal = async (student) => {
    setSelectedStudent(student);
    setModalError('');
    setModalLoading(true);
    try {
      // Fetch available subjects using the student's class_id
      const response = await api.get(`student/non-compulsory-subjects?class_id=${student.class_id}`);
      let subjects = response.data.subjects;
      // Filter out subjects that are already assigned for this enrollment
      if (student.additional_subjects && student.additional_subjects.length > 0) {
        const alreadyAssigned = student.additional_subjects.map(as => as.subject_id);
        subjects = subjects.filter(subject => !alreadyAssigned.includes(subject.id));
      }
      setAvailableSubjects(subjects);
      if (subjects.length > 0) {
        setSelectedSubjectId(subjects[0].id);
      } else {
        setSelectedSubjectId('');
      }
    } catch (err) {
      setModalError('Error fetching subjects');
      console.error(err);
    } finally {
      setModalLoading(false);
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedStudent(null);
    setAvailableSubjects([]);
    setSelectedSubjectId('');
    setModalError('');
  };

  // Handle assignment: now send enrollment_id instead of student_id.
  const handleAssignSubject = async () => {
    if (!selectedStudent || !selectedSubjectId) return;
    try {
      setModalLoading(true);
      await api.post('sUBJECT/assign-additional-subject', {
        enrollment_id: selectedStudent.enrollment_id,
        class_id: selectedStudent.class_id,
        subject_id: selectedSubjectId,
      });
      closeModal();
      fetchStudents(); // Refresh list
    } catch (err) {
      setModalError(err.response?.data?.message || 'Error assigning subject');
      console.error(err);
    } finally {
      setModalLoading(false);
    }
  };

  // Handle unassigning a specific additional subject for a student using enrollment_id.
  const handleUnassignSubject = async (student, subject_id) => {
    try {
      await api.delete('student/unassign-additional-subject', {
        data: { enrollment_id: student.enrollment_id, subject_id }
      });
      fetchStudents(); // Refresh list
    } catch (err) {
      setError(err.response?.data?.message || 'Error unassigning subject');
      console.error(err);
    }
  };

  return (
    <SchoolAdminLayout>
      <div className="assignment-container">
        <h1>Assign Additional Subjects</h1>

        {/* Session selection */}
        <div className="filters">
          <select
            value={selectedSessionId || ''}
            onChange={(e) => setSelectedSessionId(Number(e.target.value))}
          >
            {sessions.map(session => (
              <option key={session.id} value={session.id}>
                {session.name}
              </option>
            ))}
          </select>
        </div>

        {/* Filters */}
        <div className="filters">
          <input
            type="text"
            placeholder="Search by name or roll number..."
            value={searchQuery}
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setCurrentPage(1);
            }}
          />
          <select
            value={classFilter}
            onChange={(e) => {
              setClassFilter(e.target.value);
              setCurrentPage(1);
            }}
          >
            <option value="all">All Classes</option>
            {classOptions.map((cls, idx) => (
              <option key={idx} value={cls}>
                {cls}
              </option>
            ))}
          </select>
        </div>

        {loading ? (
          <p>Loading students...</p>
        ) : error ? (
          <p className="error">{error}</p>
        ) : (
          <>
            <table className="students-table">
              <thead>
                <tr>
                  <th>Full Name</th>
                  <th>Roll Number</th>
                  <th>Class</th>
                  <th>Additional Subjects</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {currentStudents.map(student => (
                  <tr key={student.enrollment_id}>
                    <td>{student.first_name} {student.last_name}</td>
                    <td>{student.roll_number || '-'}</td>
                    <td>{student.class_name}</td>
                    <td>
                      {student.additional_subjects && student.additional_subjects.length > 0 ? (
                        student.additional_subjects.map((sub, idx) => (
                          <div key={idx} className="assigned-subject">
                            {sub.name} ({sub.code}){' '}
                            <button
                              className="action-btn unassign"
                              onClick={() => handleUnassignSubject(student, sub.subject_id)}
                            >
                              Unassign
                            </button>
                          </div>
                        ))
                      ) : (
                        'None'
                      )}
                    </td>
                    <td>
                      <button className="action-btn" onClick={() => openModal(student)}>
                        Add Additional Subject
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            {totalPages > 1 && (
              <div className="pagination">
                {Array.from({ length: totalPages }, (_, index) => (
                  <button
                    key={index + 1}
                    onClick={() => setCurrentPage(index + 1)}
                    className={currentPage === index + 1 ? 'active' : ''}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            )}
          </>
        )}

        {/* Modal for assigning additional subject */}
        {modalOpen && selectedStudent && (
          <div className="modal-overlay">
            <div className="modal">
              <h2>Assign Additional Subject</h2>
              {modalLoading ? (
                <p>Loading subjects...</p>
              ) : (
                <>
                  <p>
                    <strong>Student:</strong> {selectedStudent.first_name} {selectedStudent.last_name} (Roll: {selectedStudent.roll_number})
                  </p>
                  {availableSubjects.length > 0 ? (
                    <div className="modal-field">
                      <label>Select Subject:</label>
                      <select
                        value={selectedSubjectId}
                        onChange={(e) => setSelectedSubjectId(e.target.value)}
                      >
                        {availableSubjects.map(subject => (
                          <option key={subject.id} value={subject.id}>
                            {subject.name} ({subject.code})
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : (
                    <p>No additional subjects available for this class.</p>
                  )}
                  {modalError && <p className="error">{modalError}</p>}
                  <div className="modal-buttons">
                    <button onClick={handleAssignSubject} disabled={modalLoading || !selectedSubjectId}>
                      {modalLoading ? 'Assigning...' : 'Assign Subject'}
                    </button>
                    <button onClick={closeModal} className="cancel-button" disabled={modalLoading}>
                      Cancel
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </SchoolAdminLayout>
  );
};

export default AdditionalSubjectsAssignment;